import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8137287c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-upload text-center position-relative" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "text-end me-12" }
const _hoisted_5 = {
  key: 0,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageUrls, (image, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "image-preview-container"
      }, [
        _createElementVNode("img", {
          src: image.url,
          alt: "Ficheros",
          class: "preview-image"
        }, null, 8, _hoisted_2),
        _createElementVNode("button", {
          onClick: ($event: any) => (_ctx.removeImage(index)),
          class: "btn-remove"
        }, "Eliminar", 8, _hoisted_3)
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args))),
        class: "btn btn-primary"
      }, " Subir Ficheros "),
      _createElementVNode("input", {
        type: "file",
        class: "file-input",
        ref: "fileInput",
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args))),
        multiple: ""
      }, null, 544),
      (_ctx.fileLimitReached)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Solo puedes subir un máximo de 3 archivos."))
        : _createCommentVNode("", true)
    ])
  ]))
}