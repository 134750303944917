<template>
  <div class="file-upload text-center position-relative">
    <div v-for="(image, index) in imageUrls" :key="index" class="image-preview-container">
      <img :src="image.url" alt="Ficheros" class="preview-image" />
      <button @click="removeImage(index)" class="btn-remove">Eliminar</button>
    </div>
    <div class="text-end me-12">
      <button @click="triggerFileInput" class="btn btn-primary">
        Subir Ficheros
      </button>
      <input type="file" class="file-input" ref="fileInput" @change="handleFileChange" multiple />
      <p v-if="fileLimitReached" class="text-danger">Solo puedes subir un máximo de 3 archivos.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as StateStore from "@/utils/store";

export default defineComponent({
  name: "ImageUpload",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  data() {
    return {
      selectedFiles: [] as File[],
      imageUrls: [] as { url: string; text: string }[],
      fileLimitReached: false,
    };
  },
  methods: {
    triggerFileInput() {
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      fileInput.click();
    },
    resetFileLimit() {
      this.fileLimitReached = false;
    },
    resetInput() {
      this.selectedFiles = [];
      this.imageUrls = [];
    },
    handleFileChange(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files) {
        const files = Array.from(input.files).filter(file => file.type.startsWith('image/'));
        const totalFiles = this.selectedFiles.length + files.length;

        if (totalFiles > 3) {
          this.fileLimitReached = true;
          input.value = ''; // Restablecer el valor del input
          return;
        }

        this.fileLimitReached = false;

        // Agregar los nuevos archivos a la lista existente, evitando duplicados
        files.forEach((file) => {
          const isDuplicate = this.selectedFiles.some(existingFile => existingFile.name === file.name);
          if (!isDuplicate) {
            this.selectedFiles.push(file);
          }
        });

        // Actualizar las imágenes de vista previa
        this.previewImages();

        this.$emit('files-selected', this.selectedFiles);
      }
    },
    previewImages() {
      this.imageUrls = [];
      this.selectedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target?.result) {
            this.imageUrls.push({ url: event.target.result as string, text: "" });
          }
        };
        reader.readAsDataURL(file);
      });
    },
    async uploadImages() {
      if (this.selectedFiles?.length === 0) return;

      const formData = new FormData();
      this.selectedFiles.forEach((file, index) => {
        formData.append("images[]", file);
        formData.append(`texts[${index}]`, this.imageUrls[index]?.text || "");
      });

      try {
        const response = await StateStore.actions.actionsDeleteArticles({
          axios: this.axios,
          formData,
        });

        if (response === null) {
          console.error("Image upload failed.");
        }
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    },
    removeImage(index: number) {
      this.imageUrls.splice(index, 1);
      this.selectedFiles.splice(index, 1);
      this.fileLimitReached = false;
    }
  },
});
</script>

<style scoped>
.preview-image {
  max-width: 100%;
  max-height: 300px;
  height: auto;
  margin-bottom: 10px;
  object-fit: contain;
  display: block;
}

.btn-remove {
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 20px;
  cursor: pointer;
}

.image-preview-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  align-items: center;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.text-end {
  position: relative;
}

.text-danger {
  color: red;
}
</style>
