<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-6">
            <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.name && showError }]">
              <label class="d-block text-black fw-semibold mb-10">
                Nombre
              </label>
              <input
                  v-model="item.name"
                  type="text"
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. Nombre Tópico"
              />
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.educationalSections && showError }]">
              <label class="text-black fw-semibold mb-10">
                Sección Educativa
              </label>
              <select v-model="item.educationalSections" class="form-control shadow-none rounded-0 text-black">
                <option
                    v-for="(option, index) in educationalSections"
                    :value="option.id"
                    :key="index"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.urlVideo && showError }]">
              <label class="d-block text-black fw-semibold mb-10">
                URL Video
              </label>
              <input
                  v-model="item.urlVideo"
                  type="text"
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. https://www.youtube.com/watch"
              />
            </div>
          </div>

          <div class="col-md-12">
            <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.text && showError }]">
              <label class="d-block text-black fw-semibold mb-10">
                Texto del Tópico
              </label>
              <div class="mb-0">
                <input
                    v-model="item.text"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="Descripción"
                />
                <!--                @input="handleTextChange"-->

              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Subir Ficheros
              </label>
              <ImageUpload/>
            </div>
          </div>
          <div v-if="error !== '' || showError">
            <div class="row" v-if="error != null || showError">
              <span class="text-muted text-danger">{{ error }}</span>
            </div>
          </div>
          <div v-if="success">
            <div class="row" v-if="success">
              <span class="success-message ms-2 mb-6 me-6">{{ message }}</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center justify-content-between">
              <button
                  class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                  type="submit"
                  @click.prevent="submitForm"
              >
                Aceptar
              </button>
              <button
                  type="button"
                  class="bg-transparent p-0 border-0 text-danger lh-1 fw-medium"
              >
                <i
                    class="flaticon-delete lh-1 me-1 position-relative top-2"
                ></i>
                <span class="position-relative">Cancelar</span>
              </button>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
//import ImageUpload from "./ImageUpload.vue";
import * as StateStore from "@/utils/store";
import {stateStore} from "@/utils/store";

export default {
  name: "AddTopic",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  components: {
   //ImageUpload,
  },
  data() {
    return {
      showError: null,
      selectedItem: null,
      success: null,
      message: '',
      error: '',
      item: {
        name: '',
        text: '',
        urlVideo: '',
        educationalSections: null,
        files: null,
      },
      educationalSections: []
    }
  },
  methods: {
    handleTextChange(delta, oldDelta, source) {
      // console.log("Texto actual:", this.item.text);
      // Aquí puedes agregar cualquier lógica adicional que necesites
    },
    async getData() {
      await StateStore.actions.actionsGetAllEducSectionsList({'axios': this.axios});
      this.educationalSections = StateStore.getters.getEducationalSectionsList();
    },
    async submitForm() {
      this.showError = true;
      // console.log("item: ", this.item)
      if (!this.item.name || !this.item.text || !this.item.educationalSections) {
        this.error = 'Todos los campos son necesarios';
      } else {
        this.error = '';
        this.showError = false;
        this.success = true;
        if (this.item.id > 0) {
          this.message = 'Tópico Modificado con éxito...';
        } else {
          this.message = 'Tópico Creado con éxito...';
        }
        if (this.item.id === undefined) {
          // console.log('this.id: ', this.id);
          await StateStore.actions.actionsCreateArticles({
            'axios': this.$axios,
            'name': this.item.name,
            'text': this.item.text,
            'educationalSections': this.item.educationalSections,
            // 'files': this.item.files,
            'files': [1],
          })
              .then((response) => {
                if (response || stateStore.ok) {
                  this.$router.push('/topic-list');
                } else {
                  // Maneja el error
                  console.error('Error al crear la Respuesta:', response);
                }
              }).catch((error) => {
                // Maneja cualquier error que pueda ocurrir durante la ejecución
                console.error('Error al crear el usuario:', error);
              });
        } else {
          await StateStore.actions.actionsEditArticles({
            'axios': this.$axios,
            'id': this.item.id,
            'name': this.item.name,
            'text': this.item.text,
            'educationalSections': this.item.educationalSections,
            'files': [1],
          })
              .then((response) => {
                if (response || stateStore.ok) {
                  this.$router.push('/topic-list');
                } else {
                  // Maneja el error
                  console.error('Error al editar la Respuesta:', response);
                }
              }).catch((error) => {
                // Maneja cualquier error que pueda ocurrir durante la ejecución
                console.error('Error al editar la Respuesta:', error);
              });
        }
        // console.log('Formulario enviado:', this.item);
      }
    },
  },
  async created() {
    await this.getData();
  },
  // setup: () => {
  //   const modules = {
  //     module: BlotFormatter,
  //     ImageUploader,
  //     options: {
  //       upload: (file: string | Blob) => {
  //         return new Promise((resolve, reject) => {
  //           const formData = new FormData();
  //           formData.append("image", file);
  //
  //           axios
  //               .post("/upload-image", formData)
  //               .then((res: { data: { url: unknown } }) => {
  //                 // console.log(res);
  //                 resolve(res.data.url);
  //               })
  //               .catch((err: unknown) => {
  //                 reject("Upload failed");
  //                 console.error("Error:", err);
  //               });
  //         });
  //       },
  //     },
  //   };
  //   const items = [];
  //   return {modules, items};
  // },
};
</script>
<style scoped>
.has-error input,
.has-error select {
  border-color: red;
}

.success-message {
  background-color: green;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}
</style>