import { AxiosInstance } from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class Articles {
  static async retrieve(axios: AxiosInstance, id: number | string | null) {
    const response = await axios.post(
      '/graphql/',
      `query{
                  articlesRetrieve(id:${id}){
                    id
                    name
                    text
                    urlVideo
                    image{
                      id
                      name
                    }
                    files{
                      id
                      name
                    }
                    educationalSections{
                      id
                      name
                    }
                  }
                }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    ).catch(function (error) {
      if (error.response) {
        // console.log("primer strike");
        return Promise.resolve(null);
      } else if (error.request) {
        // console.log("segundo strike");
        return Promise.resolve(null);
      }
    })
    if (response) {
      // if (
      //     response.data.data.articlesRetrieve
      // ) {
      //     response.data.data.userRetrieve.compressed = {
      //         roles: response.data.data.userRetrieve.roles,
      //     };
      // }

      // response.data.data.userRetrieve.roles = response.data.data.userRetrieve?.roles?.id

      return response.data.data.articlesRetrieve;
    }
  }

  static async all(axios: AxiosInstance) {
    const response = await axios?.post(
      "/graphql/",
      `query{
                  articlesList{
                    id
                    image{
                      id
                      name
                    }
                    files{
                      id
                      name
                    }
                    name
                    text
                    urlVideo
                    educationalSections{
                      id
                      name
                    }
                  }
                }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    );
    // console.log("articlesList: ", response.data.data?.articlesList);

    // const usersList = response.data.data?.userList;
    //
    // for(let i = 0; i < usersList?.length; i++) {
    //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
    //     usersList[i].roles = usersList[i].roles.id;
    // }
    return response.data.data?.articlesList;
  }


  static async delete(axios: AxiosInstance, id: number) {
    const response = await axios.post(
      "/graphql/",
      {
        query: `mutation deleteArticles ($id: ID!){
                      deleteArticles(id: $id){
                        ok
                      }
                    }`,
        variables: {
          id: id,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.data.deleteArticles.ok;
  }

  static async create(
    axios: AxiosInstance,
    name: string,
    educationalSections: number,
    urlVideo: string | null,
    text: string,
    image: number | null,
    files: number[],
  ) {
    const filesArray = JSON.stringify(files);
    const response = await axios?.post(
      "/graphql/",
      `mutation{
              createArticles(newArticles:{
                name:"${name}"
                educationalSections:${educationalSections}
                ${urlVideo ? `urlVideo:${urlVideo}` : ""}
                text:"${text}"
                ${image ? `image:${image}` : ""}
                files:${filesArray}
              }){
                ok
                errors{
                  field
                  messages
                }
                articles{
                  id
                  name
                  text
                  urlVideo
                  educationalSections{
                    id
                    name
                  }
                  image{
                    id
                    name
                    text
                  }
                  files{
                    id
                    name
                    text
                  }
                }
              }
            }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    ).catch(function (error) {
      if (error.response) {
        // console.log("primer strike");
        return Promise.resolve(null);
      } else if (error.request) {
        // console.log("segundo strike");
        return Promise.resolve(null);
      }
    });
    if (response && response.data && response.data.data) {
      if (response.data.data.createArticles?.ok) {
        return response.data.data.createArticles.ok;
      } else if (response.data.data.createArticles?.errors) {
        const errores = response.data.data.createArticles.errors;
        const mensajes: string[] = [];

        for (let i = 0; i < errores?.length; i++) {
          const error = errores[i];
          for (let j = 0; j < error.messages?.length; j++) {
            mensajes.push(error.messages[j]);
          }
        }
        // console.log("lo que tenemos: ", response.data.data.createArticles.errors);
        StateStore.actions.actionsSetErrorMessage(mensajes);
        return null;
      } else if (response.data.errors) {
        // console.log("lo que tenemos: ", response.data.errors);
        StateStore.actions.actionsSetErrorMessage(response.data.errors);
        return null;
      }
    } else {
      return null;
    }
  }

  static async edit(
    axios: AxiosInstance,
    id: number,
    name: string,
    educationalSections: number,
    urlVideo: string | null,
    text: string,
    image: number | null,
    files: number[],
  ) {
    const filesArray = JSON.stringify(files);
    const response = await axios?.post(
      "/graphql/",
      `mutation{
              updateArticles(newArticles:{
                id:${id}
                name:"${name}"
                educationalSections:${educationalSections}
                ${urlVideo ? `urlVideo:${urlVideo}` : ""}
                text:"${text}"
                ${image !== null ? `image:${image}` : ""}
                files:${filesArray}
              }){
                ok
                errors{
                  field
                  messages
                }
                articles{
                  id
                  name
                  text
                  urlVideo
                  educationalSections{
                    id
                    name
                  }
                  image{
                    id
                    name
                    text
                  }
                  files{
                    id
                    name
                    text
                  }
                }
              }
            }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    ).catch(function (error) {
      if (error.response) {
        // console.log("primer strike");
        return Promise.resolve(null);
      } else if (error.request) {
        // console.log("segundo strike");
        return Promise.resolve(null);
      }
    });
    if (response && response.data) {
      if (response.data.data.updateArticles.ok) {
        return response.data.data.updateArticles.ok;
      } else if (response.data.data.updateArticles.errors) {
        const errores = response.data.data.updateArticles.errors;
        const mensajes: string[] = [];

        for (let i = 0; i < errores?.length; i++) {
          const error = errores[i];
          for (let j = 0; j < error.messages?.length; j++) {
            mensajes.push(error.messages[j]);
          }
        }
        // console.log("lo que tenemos: ", response.data.data.updateArticles.errors);
        StateStore.actions.actionsSetErrorMessage(mensajes);
        return null;
      } else if (response.data.errors) {
        // console.log("lo que tenemos: ", response.data.errors);
        StateStore.actions.actionsSetErrorMessage(response.data.errors);
        return null;
      }
    } else {
      return null;
    }
  }
}
